<template>
  <div style="width: 100%">
    <crud-md :show-filters="false" :custom-actions="crudActions"></crud-md>
  </div>
</template>

<script>
export default {
  components: {
    CrudMd: () => import("@/components/crud-md"),
  },
  data: () => ({
    crudActions: {},
  }),
  beforeMount() {
    const basePath = `/clients/${this.$route.params.id}/contacts`;
    const service = this.$store.state.currentService;
    this.crudActions = {
      ...service.tab.getListActions(basePath),
      ...service.tab.createActions(basePath),
    };
  },
};
</script>
